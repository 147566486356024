import axios from "axios";
import { API_ENDPOINTS } from "../apiConfig";
import axiosHelper from "../Utilities/axiosHelper";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  RESET_STATE
} from "./types";

export const loadUser = () => async (dispatch) => {
  try {
    const res = await axiosHelper.get(API_ENDPOINTS.profileEndpoints.getProfiles);
    if (res.status === 200 || res.status === 204) {
      localStorage.setItem('mail', res.data?.email);
      localStorage.setItem('darkMode', res.data?.settings?.useDarkMode);
      dispatch({
        type: USER_LOADED,
        payload: res.data,
      });
      if (res.data?.lastLoginDateTime.includes('0001') && !localStorage.getItem('firstUser')) {
        return true;
      }else{
        return false;
      }
    }
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export function resetState() {
  return { type: RESET_STATE };
}

export const register =
  ({ name, email, password }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ name, email, password });

    try {
      const res = await axios.post("/api/users", body, config);
      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data,
      });

      dispatch(loadUser());
    } catch (err) {
      const errors = err.response.data.errors;

      if (errors) {
        // errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
      }

      dispatch({
        type: REGISTER_FAIL,
      });
    }
  };

export const login = (email, password) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({ email, password });

  try {
    const res = await axios.post("/api/auth", body, config);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });

    dispatch(loadUser());
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      // errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

export const logout = () => (dispatch) => {
  dispatch({ type: LOGOUT });
};
