import axios from 'axios';
import { API_ENDPOINTS } from '../apiConfig';
import { isTokenExpired, setTokenExpirationTime } from "./authUtility";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL, 
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true // Important: This sends the cookies with each request
});

let isRefreshing = false;
let subscribers = [];

// Function to add subscribers (pending requests)
const subscribeTokenRefresh = (cb) => {
  subscribers.push(cb);
};


// Function to get a new session token
const getNewSessionToken = async () => {

  const response = await axios.post(
    API_ENDPOINTS.baseEndpoints.refresh,
    {},  // Empty object since you're not sending any data in the body
    {
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,  // Ensure cookies are sent with the request
    }
  );


  setTokenExpirationTime()
  return response.data;
};


// Request Interceptor
axiosInstance.interceptors.request.use(
  async (config) => {
    if (
      config.url.includes('/login') ||
      config.url.includes('/refresh') ||
      config.url.includes('/signup')||
      config.url.includes('/logout')||
      config.url.includes('/resend-verification')||
      config.url.includes('/forgot-password')||
      config.url.includes('/reset-password')||
      config.url.includes('/validate-token')||
      !isTokenExpired()
    ) {
      return config;
    }

    if (!isRefreshing) {
      console.log("Token is expired, attempting to refresh.");
      isRefreshing = true;
      try {
        await getNewSessionToken();  // We no longer need to return the token, just refresh it
        isRefreshing = false;
        return config;  // Now the cookies will be sent automatically
      } catch (error) {
        console.log("Error refreshing token");
        isRefreshing = false;
        window.location.reload();  // Reload the page if refreshing fails
        return Promise.reject(error);
      }
    }

    // If token is being refreshed, return a promise that resolves once it's done
    return new Promise((resolve, reject) => {
      subscribeTokenRefresh(() => {
        resolve(config);  // No need for a new token, cookies are already refreshed
      });
    });
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response Interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle specific status codes if needed
    if (error.response) {
      console.error('Error status:', error.response.status);
      console.error('Error data:', error.response.data);

      // Optional: Handle other status codes here

      // For 401, you might not need to do anything if the request interceptor handles it
      if (error.response.status === 401) {
  
        localStorage.removeItem('tokenExpiration');
        window.location.reload();
      }

      return Promise.reject(error.response.data);
    } else if (error.request) {
      console.error('Error request:', error.request);
      return Promise.reject({ status: 400, message: error.message });
    } else {
      console.error('Error message:', error.message);
      return Promise.reject({ status: 400, message: error.message });
    }
  }
);
export default axiosInstance;
