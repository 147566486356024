import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import SettingsIcon from "@mui/icons-material/Settings";
import { Select, InputLabel, MenuItem, Input  } from "@mui/material";
import { useMaterialUIController } from "context";
import { CloseOutlined } from '@ant-design/icons';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import MDSnackbar from "components/MDSnackbar";
import { connect } from "react-redux";
import { Tabs } from 'antd';
import SymbolMapper from "./SymbolMapper";

const TradeLinkSettingsModal = ({ open, setOpen, handleSettingsChange, tradeInfo, user, saveSymbol, symbols }) => {

  const [ riskActive, setRiskActive ] = useState(true);

  const [infoSB, setInfoSB] = React.useState(false);
  const [content, setContent] = useState('');
  const closeInfoSB = () => setInfoSB(false);

  const renderInfoSB = (
    <MDSnackbar
      icon='notifications'
      title='TradeLink Setting'
      content={content}
      open={infoSB}
      onClose={closeInfoSB}
      close={closeInfoSB}
    />
  );

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (tradeInfo.settings.managmentType !== 0) {
      setRiskActive(false);
    }
  }, [setRiskActive, tradeInfo.settings.managmentType]);

  const handleManageType = (type, value) => {
    handleSettingsChange(type, value);
    if (value === 0) {
      setRiskActive(true);
    }else{
      setRiskActive(false);
    }
  }
  
	const [controller] = useMaterialUIController();
	const {
		darkMode,
	} = controller;

  let themeStyle = null;
  let selectStyle = null;
  if (darkMode) {
    themeStyle = {
      content: { backgroundColor: 'black' },
      header: { 
        backgroundColor: 'black',
        color: '#ffffff'
      }, 
      footer: { backgroundColor: 'black' },
    };

    selectStyle = {
      color: '#ffffff'
    };
  }

  var managementTypeToolTip = "Fixed lots uses a fixed value. Lot Scaling uses a value as a multiple for size scaling" 
  var riskValueToolTip = "Value used for position size management" 
  var fixedTpToolTip = "Manual override for a fixed TP in pips"
  var fixedSLToolTip = "Manual override for a fixed SL in pips" 
  var pairSuffixToolTip = "Fixed lots uses a fixed value. Lot Scaling uses a value as a multiple for size scaling" 

  const displayTip = (id) => {
    switch (id) {
      case 'managementType':
        setContent(managementTypeToolTip);
        setInfoSB(true);
        break;
      case 'riskValue':
        setContent(riskValueToolTip);
        setInfoSB(true);
        break;
      case 'fixedTp':
        setContent(fixedTpToolTip);
        setInfoSB(true);
        break;
      case 'fixedSl':
        setContent(fixedSLToolTip);
        setInfoSB(true);
        break;
      case 'pairSuffix':
        setContent(pairSuffixToolTip);
        setInfoSB(true);
        break;
      default:
          return;
    }
  }

  const textColor = React.useMemo(()=> {
    if (darkMode) {
      return '#ffffff';
    }else{
      return 'black';
    }
  }, [darkMode]);
  
  const items = [
    {
      key: '1',
      label: <h1 style={{ color: textColor, fontSize:'19px', fontWeight: 500 }}>Override Settings</h1>,
      children: 
        <>
          <FormControl variant='standard' fullWidth style={{ marginBottom: 16, marginTop: 20  }}>
            <InputLabel htmlFor='input-with-icon-adornment'>
                ManagmentType
                <Tooltip title={managementTypeToolTip} placement="top">
                  <IconButton onClick={() => displayTip('managementType')}>
                    {darkMode? 
                      <ErrorOutlineIcon sx={{ color: '#ffffff' }}/> 
                      :
                      <ErrorOutlineIcon/>
                    }
                  </IconButton>
                </Tooltip>
              </InputLabel>
            <Select
              defaultValue={0}
              sx={selectStyle}
              value={tradeInfo.settings.managmentType}
              className="h-10"
              readOnly={!user?.isSubscribed}
              onChange={(e) => handleManageType('managmentType', e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  {darkMode?
                  <SettingsIcon style={{ color: '#ffffff' }}/>
                  :
                  <SettingsIcon />}
                </InputAdornment>
              }
              >
              <MenuItem key={0} value={0}>
                None
              </MenuItem>
              <MenuItem key={1} value={1}>
                Fixed Lots
              </MenuItem>
              <MenuItem key={2} value={2}>
                LotScalling
              </MenuItem>
            </Select>
          </FormControl>
  
          <FormControl variant='standard' fullWidth style={{ marginBottom: 16 }}>
            <InputLabel htmlFor='input-with-icon-adornment'>
              RiskValue
              <Tooltip title={riskValueToolTip} placement="top">
                <IconButton onClick={() => displayTip('riskValue')}>
                  {darkMode? 
                    <ErrorOutlineIcon sx={{ color: '#ffffff' }}/> 
                    :
                    <ErrorOutlineIcon/>
                  }
                </IconButton>
              </Tooltip>
            </InputLabel>
            <Input
              id='riskValue'
              className='h-10'
              type='number'
              variant="standard"
              inputProps={{ step: 0.0000001 }}
              label="RiskValue"
              readOnly = {riskActive || (!user?.isSubscribed)}
              value={riskActive? 0 : tradeInfo.settings.riskValue}
              onChange={(e) => handleSettingsChange('riskValue', parseFloat(e.target.value))}
              startAdornment={
                <InputAdornment position='start'>
                  {darkMode?
                  <SettingsIcon style={{ color: '#ffffff' }}/>
                  :
                  <SettingsIcon />}
                </InputAdornment>
              }
            />
          </FormControl>
  
          <FormControl variant="standard" fullWidth style={{ marginBottom: 16}}>
              <InputLabel htmlFor='input-with-icon-adornment'>
                FixedTp
                <Tooltip title={fixedTpToolTip} placement="top">
                  <IconButton onClick={() => displayTip('fixedTp')}>
                    {darkMode? 
                      <ErrorOutlineIcon sx={{ color: '#ffffff' }}/> 
                      :
                      <ErrorOutlineIcon/>
                    }
                  </IconButton>
                </Tooltip>
              </InputLabel>
            <Input
              id="fixedTp"
              className="h-10"
              type="number"
              variant="standard"
              inputProps={{ step: 0.0000001 }}
              label="FixedTp"
              readOnly={!user?.isSubscribed}
              value={tradeInfo.settings.fixedTp}
              onChange={(e) => handleSettingsChange("fixedTp", parseFloat(e.target.value))}
              startAdornment={
                <InputAdornment position="start">
                  {darkMode?
                  <SettingsIcon style={{ color: '#ffffff' }}/>
                  :
                  <SettingsIcon />}
                </InputAdornment>
              }
            />
          </FormControl>
  
          <FormControl variant='standard' fullWidth style={{ marginBottom: 16 }}>
            <InputLabel htmlFor='input-with-icon-adornment'>
              FixedSl
              <Tooltip title={fixedSLToolTip} placement="top">
                <IconButton onClick={() => displayTip('fixedSl')}>
                  {darkMode? 
                    <ErrorOutlineIcon sx={{ color: '#ffffff' }}/> 
                    :
                    <ErrorOutlineIcon/>
                  }
                </IconButton>
              </Tooltip>
            </InputLabel>
            <Input
              id='fixedSl'
              className='h-10'
              type='number'
              label="FixedSl"
              inputProps={{ step: 0.0000001 }}
              variant="standard"
              readOnly={!user?.isSubscribed}
              value={tradeInfo.settings.fixedSl}
              onChange={(e) => handleSettingsChange('fixedSl', parseFloat(e.target.value))}
              startAdornment={
                <InputAdornment position='start'>
                  {darkMode?
                  <SettingsIcon style={{ color: '#ffffff' }}/>
                  :
                  <SettingsIcon />}
                </InputAdornment>
              }
            />
          </FormControl>
  
          <FormControl variant='standard' fullWidth style={{ marginBottom: 16 }}>
            <InputLabel htmlFor='input-with-icon-adornment'>
              PairSuffix
              <Tooltip title={pairSuffixToolTip} placement="top">
                <IconButton onClick={() => displayTip('pairSuffix')}>
                  {darkMode? 
                    <ErrorOutlineIcon sx={{ color: '#ffffff' }}/> 
                    :
                    <ErrorOutlineIcon/>
                  }
                </IconButton>
              </Tooltip>
            </InputLabel>
            <Input
              id='pairSuffix'
              className='h-10'
              placeholder="Enter pairSuffix"
              type='text'
              readOnly={!user?.isSubscribed}
              value={tradeInfo.settings.pairSuffix.toString()}
              onChange={(e) => handleSettingsChange('pairSuffix', e.target.value)}
              startAdornment={
                <InputAdornment position='start'>
                  {darkMode?
                  <SettingsIcon style={{ color: '#ffffff' }}/>
                  :
                  <SettingsIcon />}
                </InputAdornment>
              }
            />
          </FormControl>
        </>
    },
    {
      key: '2',
      label: <h1 style={{ color: textColor, fontSize:'19px', fontWeight: 500 }}>Symbol Mapper</h1>,
      children: 
        <SymbolMapper darkMode={darkMode} textColor={textColor} symbols={symbols} saveSymbol={saveSymbol} />,
    }
  ];

  return (
    <Modal
      centered
      open={open}
      onOk={handleClose}
      styles={themeStyle}
      onCancel={handleClose}
      closeIcon={<CloseOutlined style={selectStyle} />}
      okButtonProps={{ disabled: false }}
      cancelButtonProps={{
        disabled: false,
      }}
      footer={[
      ]}
    >
      {renderInfoSB}
      <Tabs defaultActiveKey="1" items={items} />

    </Modal>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth?.user,
});

export default connect(mapStateToProps, null)(TradeLinkSettingsModal);
