import axiosHelper from "../Utilities/axiosHelper";
import {
    GET_TRADELINK,
    TRADE_HISTORY,
    TRADELINK_ERROR,
    SET_CHANGE,
    SET_OPENDIA,
    ADD_TRADELINK,
    AGREE_SAVE,
} from "./types";
import { API_ENDPOINTS } from "../apiConfig";

export const agreeSave = (value) => (dispatch) => {
  try {
    dispatch({
      type: AGREE_SAVE,
      payload: value,
    });

  } catch (err) {
  }
}

export const getTradeHistory = (page) => async (dispatch) => {
    try {
        let url = API_ENDPOINTS.trade.history + "?pageNumber=" + page;
        var response = await axiosHelper.get(
            url
        );
        if (response.status === 200) {
          const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
          let data = response.data;
          for (let i = 0; i < data.length; i++) {
            const utcTime = data[i]['timeStamp'];
            const date = new Date(utcTime);
            const options = {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
              hour12: true, 
              timeZone: userTimezone 
            };
            const formattedDate = new Intl.DateTimeFormat('default', options).format(date);
            data[i]['timeStamp'] = formattedDate;
          }
          dispatch({
              type: TRADE_HISTORY,
              payload: data,
          });
        }else{
          return {status: response.status, message: response.message};
        }
    } catch (err) {
  }
};

export const getTradeLink = () => async (dispatch) => {
  try {
    var tradeLink = (
      await axiosHelper.get(API_ENDPOINTS.trade.link)
    );

    if (tradeLink.status === 200) {
      dispatch({
        type: GET_TRADELINK,
        payload: tradeLink.data,
      });
    }else{
      dispatch({
        type: TRADELINK_ERROR,
        payload: { msg: tradeLink.message },
      });
    }
    return tradeLink;
  } catch (err) {
    localStorage.removeItem('tokenExpiration');
    window.location.reload();
  }
};

export const postTradeLink = (data) => async (dispatch) => {
  try {

    var saveTradeLink = (
      await axiosHelper.post(
        API_ENDPOINTS.trade.link,
        data
      )
    );
    if (saveTradeLink.status === 200) {
      dispatch(getTradeLink());
    };
    return saveTradeLink;

  } catch (err) {
    dispatch({
      type: TRADELINK_ERROR,
      payload: { msg: err.response, status: err.response.status },
    });
  }
};

export const putTradeLink = (data) => async (dispatch) => {
  try {

    var saveTradeLink = (
      await axiosHelper.put(
        API_ENDPOINTS.trade.link,
        data
      )
    );
    return saveTradeLink;
  } catch (err) {
    dispatch({
      type: TRADELINK_ERROR,
      payload: { msg: err.response, status: err.response.status },
    });
  }
};

export const activeTradeLink = (url) => async (dispatch) => {
  try {
    var activeTradeLink = (
      await axiosHelper.post(url)
    );
    return activeTradeLink;

  } catch (err) {
    dispatch({
      type: TRADELINK_ERROR,
      payload: { msg: err.response, status: err.response.status },
    });
  }
}

export const deleteTradeLink = (linkId) => async (dispatch) => {
  try {
    var url = API_ENDPOINTS.trade.link + "?tradeLinkId=" + linkId;
    var deleteTradeLink = (
      await axiosHelper.delete(
        url
      )
    );
    if (deleteTradeLink.status === 200) {
      dispatch(getTradeLink());
    }
    return deleteTradeLink;

  } catch (err) {
    dispatch({
      type: TRADELINK_ERROR,
      payload: { msg: err.response, status: err.response.status },
    });
  }
};

export const setChange = (value) => async (dispatch) => {
  try {
    dispatch({
      type: SET_CHANGE,
      payload: value,
    });
  } catch (err) {
  }
};

export const setOpenDialog = (value) => async (dispatch) => {
  try {
    dispatch({
      type: SET_OPENDIA,
      payload: value,
    });
  } catch (err) {
  }
};

export const addTradeLink = (value) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_TRADELINK,
      payload: value,
    });

  } catch (err) {
  }
};